"use client";
import {
  Col,
  Row,
  Typography,
  Card,
  Divider,
  Tag,
  Button,
  Skeleton,
} from "antd";
import Image from "next/image";
import { useMemo, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRouter } from "next/navigation";
import moment from "moment";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { shortenString, wrapperRouterPush } from "@/src/util/util";
import { getDiariesHome } from "@/src/app/service";
import useSessionUser from "@/src/hooks/useSessionUser";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ImageCommon from "../image/image";
import "./knowledge-dt.scss";
// import "@/src/style/common.css";
import "./diary-home.scss";

import iconArrow from "@/public/icon/icon-swiper.svg";
import iconStar from "@/public/icon/icon-start-3d.svg";
import RightArrow from "@/public/icon/right-bold.svg";
import { useSession } from "next-auth/react";

const { Title, Text } = Typography;

export default function DiaryHome({ svSession, type }) {
  const router = useRouter();
  const [diaryData, setDiaryData] = useState(Array.from({ length: 6 }));
  const [loading, setLoading] = useState(true);

  const { data: session } = useSession();
  const sessionUser = useSessionUser(svSession, session);
  const slicedData = useMemo(
    () =>
      diaryData.reduce((result, _, index, array) => {
        if (index % 4 === 0) result.push(array.slice(index, index + 4));
        return result;
      }, []),
    [diaryData]
  );

  useEffect(() => {
    (async () => {
      try {
        const res = await getDiariesHome();
        setDiaryData(res.data || []);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const renderImages = (condition) => {
    const startImage = condition?.find((item) => item.type === "START");
    const endImage = condition?.filter((item) => item.type !== "START").pop();

    return (
      <>
        {startImage && (
          <div className="even-clum">
            <ImageCommon
              data={startImage.image[0]}
              style="processImg"
              alt="Trước"
            />
            <Tag className="timeBracess">Trước</Tag>
          </div>
        )}
        {endImage && (
          <div className="even-clum" style={{ marginLeft: "10px" }}>
            <ImageCommon
              data={endImage.image[0]}
              style="processImg"
              alt="Sau"
            />
            <Tag className="timeBracess">Sau</Tag>
          </div>
        )}
        {!startImage && !endImage && (
          <>
            <div className="even-clum">
              <ImageCommon style="processImg" />
            </div>
            <div className="even-clum" style={{ marginLeft: "10px" }}>
              <ImageCommon style="processImg" />
            </div>
          </>
        )}
      </>
    );
  };

  const renderDetails = (subItem) => {
    const isLogined = type === "CLINIC" || sessionUser;
    return (
      <div className="space-content">
        <Title className="titleContent mb-7" level={4}>
          {subItem?.title}
        </Title>
        <Row
          className="mb-7"
          justify="start"
          align="middle"
          style={{ flexWrap: "nowrap" }}
        >
          <Row
            className={`address ${isLogined ? "addressLogined" : ""}`}
            align="middle"
            style={{ flexWrap: "nowrap" }}
            onClick={(e) => {
              if (isLogined) {
                e.stopPropagation();
                wrapperRouterPush(
                  router,
                  `/phong-kham/${subItem.clinic?.slug}`
                );
              }
            }}
          >
            <ImageCommon
              style="logo-clinic"
              data={
                subItem?.rating
                  ? subItem?.user?.image[0]
                  : subItem?.clinic?.image[0]
              }
            />
            <span className="addressText">
              {shortenString(
                subItem?.rating ? subItem?.user?.name : subItem?.clinic_name,
                50
              )}
            </span>
          </Row>
          {type !== "CLINIC" && subItem?.rating && (
            <>
              <Divider type="vertical" />
              <div className="ratingPoint">
                <div className="point">
                  {subItem?.rating?.average?.toFixed(1)}
                </div>
                <Image className="iconRatingStar" src={iconStar} alt="Rating" />
              </div>
            </>
          )}
        </Row>
        <Button className="see-detail">Xem chi tiết</Button>
      </div>
    );
  };

  return (
    <>
      {diaryData?.length > 0 && (
        <div className="wp-knowledgeDentistry wp-showcase-home">
          <div className="wp-title">
            <div className="title">
              <h2>SHOWCASE</h2>
            </div>
            <p className="subTitle">
              Tham khảo showcase thực tế của những người đã sử dụng dịch vụ tại
              các cơ sở nha khoa trên toàn quốc.
            </p>
          </div>
          <div className="wp-content-diary-home">
            <Swiper
              slidesPerView={1}
              autoplay={{ delay: 4000, disableOnInteraction: false }}
              loop
              navigation={{
                nextEl: type === "CLINIC" ? "#clinic-R" : "#user-R",
                prevEl: type === "CLINIC" ? "#clinic-L" : "#user-L",
              }}
              pagination={{ clickable: true }}
              modules={[Autoplay, Pagination, Navigation]}
            >
              {slicedData.map((items, index) => (
                <SwiperSlide key={index}>
                  <div className="wp-perview">
                    <Row gutter={[12, 12]}>
                      {items.map((subItem, idx) => (
                        <Col xl={12} md={12} key={idx}>
                          {loading ? (
                            <Skeleton.Input
                              className="diary-home-skeleton"
                              active
                            />
                          ) : (
                            <div
                              onClick={() =>
                                wrapperRouterPush(
                                  router,
                                  `/showcase/${subItem?.slug}`
                                )
                              }
                              className="wp-contentItem"
                            >
                              <Card className="content">
                                <Row gutter={[20, 16]}>
                                  <Col
                                    xs={24}
                                    sm={24}
                                    lg={12}
                                    xl={12}
                                    className="left-content"
                                  >
                                    <div className="wp-image">
                                      {renderImages(subItem?.dental_condition)}
                                    </div>
                                    <div className="wp-statistical">
                                      <Text className="view">
                                        <Text strong>
                                          {subItem?.total_view}
                                        </Text>{" "}
                                        Lượt xem
                                      </Text>
                                      {/* for read only */}
                                      {/* <Divider
                                        style={{ width: "2px" }}
                                        type="vertical"
                                      />
                                      <Text className="comments">
                                        <Text strong>
                                          {subItem?.total_comment}
                                        </Text>{" "}
                                        Bình luận
                                      </Text> */}
                                      <Divider type="vertical" />
                                      <Text className="date">
                                        {moment(subItem?.created_at).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </Text>
                                    </div>
                                  </Col>
                                  <Col xs={24} sm={24} lg={12} xl={12}>
                                    {renderDetails(subItem)}
                                  </Col>
                                </Row>
                              </Card>
                            </div>
                          )}
                        </Col>
                      ))}
                    </Row>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <Button className="see-more-button" href="/showcase">
            Xem thêm <Image src={RightArrow} alt="Arrow icon" />
          </Button>
          {slicedData.length > 1 && (
            <>
              <button
                className="arrow-left arrow"
                id={type === "CLINIC" ? "clinic-L" : "user-L"}
              >
                <Image src={iconArrow} className="icon-prev" alt="Arrow icon" />
              </button>
              <button
                className="arrow-right arrow"
                id={type === "CLINIC" ? "clinic-R" : "user-R"}
              >
                <Image src={iconArrow} alt="Arrow icon" />
              </button>
            </>
          )}
        </div>
      )}
    </>
  );
}
