"use client";
import styles from "@/src/component/blog/category-blog.module.scss";
// import "@/src/style/common.css";
import iconRight from "@/public/icon/icon-right-blueblack.svg";
import { Row, Col, Image as ImageAntd, Pagination, Button } from "antd";
import Image from "next/image";
import { wrapperRouterPush } from "@/src/util/util";
import { useRouter } from "next/navigation";
import { FALLBACK } from "@/src/const/const";
import moment from "moment";
import Link from "next/link";
import { useEffect, useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import { toSlug } from "@/src/util/util";
import { usePathname } from "next/navigation";
import iconDoubleLeft from "@/public/icon/icon-double-left.svg";

export default function CategoryBlog({ data, type, hidden }) {
  const pathName = usePathname();
  const dataMeta = data?.meta;
  const blogPosts = data?.result;
  const categorySlug = toSlug(dataMeta?.categoryName);
  const router = useRouter();
  const isNotFound = type === "NOT_FOUND";
  const onChangePage = (page) => {
    wrapperRouterPush(router, `/blog/${categorySlug}?page=${page}`);
  };
  function seeMore() {
    wrapperRouterPush(router, `/blog/${categorySlug}`);
  }
  return (
    <div
      className={`${styles.wpCategoryBlog} ${
        hidden ? `${styles.hiddenBlock}` : ""
      }`}
    >
      {!isNotFound && (
        <div className={isNotFound ? styles.wpHeaderNotFound : styles.wpHeader}>
          {isNotFound || pathName === "/blog" ? (
            <h2 className={styles.headerTitle}>{dataMeta?.categoryName}</h2>
          ) : (
            <h1 className={styles.headerTitle}>{dataMeta?.categoryName}</h1>
          )}
          {pathName == "/blog" && (
            <div className={styles.headerButton}>
              <div className={styles.text} onClick={() => seeMore()}>
                XEM THÊM
              </div>
              <Image src={iconRight} alt="Arrow icon"></Image>
            </div>
          )}
        </div>
      )}
      {blogPosts && (
        <Link
          href={
            type == "ADS"
              ? `/khuyen-mai/${blogPosts[0].slug}`
              : `/blog/${blogPosts[0].slug}`
          }
          className={styles.wpFirstItem}
        >
          <div className={styles.wpFirstContentImage}>
            <ImageAntd
              className={styles.firstContentImage}
              src={blogPosts[0].thumb ? blogPosts[0].thumb.toString() : "false"}
              width="100%"
              height="auto"
              preview={false}
              fallback={FALLBACK}
            ></ImageAntd>
          </div>
          <div className={styles.wpFirstContent}>
            <div
              className={styles.firstItemTitle}
              dangerouslySetInnerHTML={{ __html: blogPosts[0].title }}
            ></div>
            <div
              className={styles.firstItemContent}
              dangerouslySetInnerHTML={{ __html: blogPosts[0].desc }}
            ></div>

            <div className={styles.firstItemBlog}>
              {moment(blogPosts[0].date).format("DD/MM/YYYY")}
            </div>
            <Button
              style={{
                borderRadius: isNotFound ? 30 : 8,
                width: isNotFound ? 172 : "auto",
              }}
            >
              Xem chi tiết
              <RightOutlined />
            </Button>
          </div>
        </Link>
      )}
      {dataMeta?.total == 0 && (
        <div className={styles.noPosts}>Chưa có bài viết</div>
      )}
      <div className={styles.wpMainContent}>
        <Row
          gutter={[
            { xs: 0, sm: 0, md: 30, xl: 30 },
            { xs: 28, sm: 28, md: 32, xl: 32 },
          ]}
        >
          {blogPosts?.slice(1).map((item) => (
            <Col xs={24} sm={24} md={8} xl={8} className={styles.wpItemContent}>
              <Link
                href={
                  type == "ADS"
                    ? `/khuyen-mai/${item.slug}`
                    : `/blog/${item.slug}`
                }
              >
                <div className={styles.wpContentImage}>
                  <ImageAntd
                    className={styles.contentImage}
                    src={item.thumb ? item.thumb.toString() : "false"}
                    width="100%"
                    height="auto"
                    preview={false}
                    fallback={FALLBACK}
                  ></ImageAntd>
                </div>
                <div className={styles.itemBlog}>
                  {moment(item.date).format("DD/MM/YYYY")}
                </div>
                <div
                  className={styles.itemTitle}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                ></div>
                <div
                  className={styles.itemContent}
                  dangerouslySetInnerHTML={{ __html: item.desc }}
                ></div>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
      {dataMeta?.total &&
        dataMeta.total > 12 &&
        pathName != "/blog" &&
        !isNotFound && (
          <div className={styles.pagination}>
            <Pagination
              current={parseInt(dataMeta?.current)}
              total={dataMeta.total}
              pageSize={13}
              showSizeChanger={false}
              itemRender={(page, type, defaultItem) => {
                if (type == "page")
                  return <Link href={`${pathName}?page=${page}`}>{page}</Link>;
                return defaultItem;
              }}
            />
          </div>
        )}
      {isNotFound && (
        <div className={styles.readMoreNotFound}>
          <Button className={styles.readMoreBtn} onClick={() => seeMore()}>
            Xem thêm
            <Image
              src={iconDoubleLeft}
              alt="Arrow left icon"
              className={styles.readMoreIcon}
            />
          </Button>
        </div>
      )}
    </div>
  );
}
