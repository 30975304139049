"use client";
import styles from "./not-found.module.scss";
import "./not-found.css";
import "@/src/component/blog/blog-search.scss";
import React, { useEffect, useState } from "react";
import { createContactAdvice, getBlog, searchClinics } from "@/src/app/service";
import { searchClinic } from "@/src/app/tim-nha-khoa/service";
import {
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Skeleton,
  Tabs,
} from "antd";
import icon404 from "../../../public/icon/icon-404.svg";
import icon404Mobile from "../../../public/icon/icon-404-mobile.svg";
import Image from "next/image";
import iconSearch from "@/public/icon/icon-search-blog.svg";
import { searchBlogPost } from "@/src/component/blog/blog-service";
import Link from "next/link";
import defaultImage from "@/public/image/default-image-square.png";
import { LoadingOutlined } from "@ant-design/icons";
import { useRouter } from "next/navigation";
import CategoryBlog from "@/src/component/blog/category-blog";
import logo_doc from "@/public/image/logo_doc.webp";
import TextArea from "antd/es/input/TextArea";
import { strVNForSearch, wrapperRouterPush } from "@/src/util/util";
import { searchDoctor } from "@/src/app/bac-si/service";
import ImageCommon from "@/src/component/image/image";
import _ from "lodash";
import { REGION_TAG_FOR_REMARKABLE, TOP_PROVINCE_HOT } from "@/src/const/const";
import { searchTopDentistry } from "@/src/component/home/search-province-dt";
import ItemDentistry from "@/src/app/not-found/item-dentistry";
import iconDoubleLeft from "@/public/icon/icon-double-left.svg";
import "../../component/home/item-skeleton.scss";
import { PROVINCE } from "@/src/const/variable";

const FormItem = Form.Item;

export default function NotFoundClient() {
  const [activeKey, setActiveKey] = useState("1");
  const [stateReputableData, setStateReputableData] = useState([]);
  const [form] = Form.useForm();
  const [optionsName, setOptionsName] = useState([]);
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const [dataBlog, setDataBlog] = useState();
  const [dataClinic, setDataClinic] = useState([]);
  const [successSent, setSuccessSent] = useState(false);
  const listCategoryBlog = [
    "khuyen-mai",
    "review-nha-khoa",
    "kien-thuc-nha-khoa",
  ];

  const isMobile = typeof window !== "undefined" && window.innerWidth < 768;

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await getBlog(1, "review-nha-khoa", 4);
        setDataBlog(res.data);
        const resTopDentistry = await searchTopDentistry(
          1,
          null,
          "REMARKABLE",
          3
        );
        setStateReputableData(resTopDentistry?.data || []);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, []);

  const filterOption = (input, option) => {
    if (option.props.value) {
      return strVNForSearch(option.props.children).includes(
        strVNForSearch(input)
      );
    } else {
      return false;
    }
  };
  const onSubmit = async () => {
    const fieldsValue = await form.validateFields();
    try {
      await createContactAdvice({
        ...fieldsValue,
        type: "GENERAL",
      });
      form.resetFields();
      setSuccessSent(true);
    } catch (error) {
      message.error("Kiểm tra lại Thông tin đăng bài!");
    }
  };
  const onSelectProvince = async (value) => {
    const listClinic = await searchClinic("", value);
    setDataClinic(listClinic.data.data);
  };

  const onChangeTab = async (key) => {
    setLoading(true);
    setActiveKey(key);
    try {
      let res = null;
      if (parseInt(key))
        res = await searchTopDentistry(key, null, "REMARKABLE", 3);
      else res = await searchTopDentistry(null, key, "REMARKABLE", 3);
      if (res?.data) {
        setStateReputableData(res?.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function htmlToText(htmlString) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || "";
  }
  const onSearchName = (searchText) => {
    debounceOnChangeInput(searchText);
  };
  const debounceOnChangeInput = _.debounce((event) => {
    requestSearchName(event);
  }, 300);

  const requestSearchName = async (searchText) => {
    if (searchText) {
      setLoading(true);
      const result = await Promise.all([
        searchClinics(searchText, 5),
        searchDoctor(searchText, "", "", "", 1, 5),
        searchBlogPost(searchText),
      ]);
      console.log(result);
      const newOption = [
        {
          label: (
            <div>
              Phòng khám
              <a
                href="/phong-kham"
                target="_blank"
                rel="noopener noreferrer"
              ></a>
            </div>
          ),
          options: result[0]?.data?.map((item) => {
            return {
              value: htmlToText(item.name),
              key: item.id,
              label: (
                <Link href={`/phong-kham/${item.slug}`}>
                  <div className="item-result-blog">
                    <ImageCommon
                      data={item.image.find(
                        (item) => item.image_type === "AVATAR"
                      )}
                      style={"logo-on-search-blog"}
                      height={30}
                      width={30}
                      alt="Blog image"
                    />
                    <div className="wp-text">
                      <div className="title-blog">{item.name}</div>
                      {item.introduce && (
                        <p
                          className="desc-blog"
                          dangerouslySetInnerHTML={{ __html: item.introduce }}
                        ></p>
                      )}
                    </div>
                  </div>
                </Link>
              ),
            };
          }),
        },
        {
          label: (
            <div>
              Bác sĩ
              <a href="/bac-si" target="_blank" rel="noopener noreferrer"></a>
            </div>
          ),
          options: result[1]?.data?.map((item) => {
            return {
              value: htmlToText(item.name),
              key: item.id,
              label: (
                <Link href={`/bac-si/${item.slug}`}>
                  <div className="item-result-blog">
                    <ImageCommon
                      data={item.image.find(
                        (item) => item.image_type === "AVATAR"
                      )}
                      style={"logo-on-search-blog"}
                      height={30}
                      width={30}
                      alt="Blog image"
                    />
                    <div className="wp-text">
                      <div className="title-blog">{item.name}</div>
                      {item.introduce && (
                        <p
                          className="desc-blog"
                          dangerouslySetInnerHTML={{ __html: item.introduce }}
                        ></p>
                      )}
                    </div>
                  </div>
                </Link>
              ),
            };
          }),
        },
        {
          label: (
            <div>
              Blog
              <a href="/blog" target="_blank" rel="noopener noreferrer"></a>
            </div>
          ),
          options: result[2]?.data?.slice(0, 5).map((item) => {
            return {
              value: htmlToText(item.title),
              key: item.title,
              label: (
                <Link href={`/blog/${item.slug}`}>
                  <div className="item-result-blog">
                    <Image
                      src={item.thumbnail ? item.thumbnail : defaultImage}
                      className={"logo-on-search-blog"}
                      height={30}
                      width={30}
                      alt="Blog image"
                    />
                    <div className="wp-text">
                      <div
                        dangerouslySetInnerHTML={{ __html: item.title }}
                        className="title-blog"
                      ></div>
                      {item.desc && <p className="desc-blog">{item.desc}</p>}
                    </div>
                  </div>
                </Link>
              ),
            };
          }),
        },
      ];
      if (newOption) {
        setOptionsName(newOption);
        setLoading(false);
      } else {
        setOptionsName([]);
        setLoading(false);
      }
    }
  };

  const redirect = (type) => {
    type === "back" ? router.back() : router.push("/");
  };

  return (
    <div className={styles.notFoundContainer}>
      <div className={styles.notFoundHeader}>
        <div className={styles.headerBlock}>
          <Image src={icon404} className={styles.iconNotFound}></Image>
          <Image
            src={icon404Mobile}
            className={styles.iconNotFoundMobile}
          ></Image>
          <h1 className={styles.text}>TRANG KHÔNG CÓ NỘI DUNG</h1>
          <div className={styles.search}>
            <div className="wrapper-input-search-not-found">
              <AutoComplete
                id="wrapper-input-search-blog-id"
                className="wrapper-input-search"
                popupClassName="popup-search-blog"
                options={optionsName}
                onSearch={onSearchName}
                labelInValue={true}
                filterOption={false}
                notFoundContent={"Không có bài viết"}
                dropdownAlign={{
                  points: ["tl", "bl"], // align dropdown bottom-left to top-left of input element
                  offset: [0, -4], // align offset
                  overflow: {
                    adjustX: 0,
                    adjustY: 0, // do not auto flip in y-axis
                  },
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
              >
                <Input.Search
                  size="large"
                  placeholder="Tìm kiếm nhanh"
                  enterButton={
                    <>
                      {loading && (
                        <LoadingOutlined
                          className={styles.loading}
                          style={{
                            color: "grey",
                            marginRight: "8px",
                            fontSize: 24,
                          }}
                        />
                      )}
                      <Image
                        src={iconSearch}
                        className={styles.iconSearch}
                        alt="Search icon"
                      />
                    </>
                  }
                />
              </AutoComplete>
            </div>
          </div>
          <div className={styles.direct}>
            <Button className={styles.back} onClick={() => redirect("back")}>
              Quay lại
            </Button>
            <Button
              className={styles.home}
              onClick={() => {
                wrapperRouterPush(router, "/");
              }}
            >
              Trang chủ
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.notFoundBody}>
        <div className={styles.bodyContent}>
          <div className={styles.leftBlock}>
            <div className={styles.categoryContainer}>
              <div
                className={`${styles.reputableDentistry} reputable-dentistry`}
              >
                <div className={styles.wpTitle}>
                  <div className={styles.title}>
                    <h2>Nha khoa nổi bật</h2>
                  </div>
                  <p className={styles.subTitle}>
                    Top nha khoa uy tín được đánh giá bởi NhaKhoaHub & hơn
                    50.000+ người dùng
                  </p>
                </div>
                <Tabs
                  items={REGION_TAG_FOR_REMARKABLE}
                  activeKey={activeKey}
                  onChange={(key) => {
                    onChangeTab(key);
                  }}
                  tabBarExtraContent={""}
                />
                <Tabs
                  items={TOP_PROVINCE_HOT}
                  activeKey={activeKey}
                  onChange={(key) => {
                    onChangeTab(key);
                  }}
                  tabBarExtraContent={""}
                />
                <div className={styles.listDentistry}>
                  <Row gutter={[{ xl: 30, md: 30, xs: 0, sm: 12 }, 20]}>
                    {stateReputableData?.map((item, index) => (
                      <Col span={24} key={index}>
                        {!loading && (
                          <ItemDentistry data={item}></ItemDentistry>
                        )}
                        {loading && (
                          <div className="wpSkeletonItem">
                            <div className="wpSkeletonInfo">
                              <Skeleton.Avatar
                                className="skeletonLogo"
                                active
                              />
                              <div className="wpSkeletonDetails">
                                <Skeleton.Input
                                  className="skeletonInput1"
                                  active
                                />
                                <Skeleton.Input
                                  className="skeletonInput2"
                                  active
                                />
                                <div className="wpSkeletonButton">
                                  <Skeleton.Button
                                    className="skeletonButton1"
                                    active
                                  />
                                  <Skeleton.Button
                                    className="skeletonButton2"
                                    active
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Col>
                    ))}
                  </Row>
                  <div className={styles.readMoreNotFound}>
                    <Button
                      className={styles.readMoreBtn}
                      onClick={() => wrapperRouterPush(router, `/phong-kham`)}
                    >
                      Xem thêm
                      <Image
                        src={iconDoubleLeft}
                        alt="Arrow left icon"
                        className={styles.readMoreIcon}
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.categoryContainer}>
              <div className={styles.bodyTitle}>BÀI VIẾT NỔI BẬT</div>
              <CategoryBlog data={dataBlog} type={"NOT_FOUND"} />
            </div>
          </div>
          <div className={styles.rightBlock}>
            <div className={styles.formContact}>
              {successSent && (
                <div className={styles.formSuccess}>
                  <Image
                    src={logo_doc}
                    alt="logo_doc"
                    className={styles.logo}
                  />
                  <div className={styles.titleAbove}>
                    Nền tảng tìm kiếm nha khoa & review nha khoa hàng đầu
                  </div>
                  <div className={styles.titleSuccess}>
                    Bạn đã đăng ký thành công!
                  </div>
                  <div className={styles.titleBelow}>
                    Cảm ơn bạn đã tin tưởng và sử dụng dịch vụ của chúng tôi!
                  </div>
                </div>
              )}
              {!successSent && (
                <>
                  <div className={styles.title}>Đặt lịch tư vấn miễn phí!</div>
                  <Form
                    form={form}
                    layout="vertical"
                    className="wrapper-form-contact-blog"
                    onSubmit={onSubmit}
                  >
                    <Row gutter={[24, 0]}>
                      <Col xl={24} xs={12} sm={12} md={24}>
                        <FormItem
                          name="customer_name"
                          rules={[
                            {
                              required: true,
                              message: "Bạn chưa nhập Họ tên",
                            },
                          ]}
                          label={
                            <label style={{ color: "#FFFFFF" }}>
                              Họ và tên:
                            </label>
                          }
                        >
                          <Input className={styles.customInput} />
                        </FormItem>
                      </Col>
                      <Col xl={24} xs={12} sm={12} md={24}>
                        <FormItem
                          name="customer_phone_number"
                          rules={[
                            {
                              required: true,
                              message: "Bạn chưa nhập Số điện thoại",
                            },
                            {
                              pattern: /(03|05|07|08|09)+([0-9]{8})\b/,
                              message: "Số điện thoại không đúng định dạng",
                            },
                          ]}
                          label={
                            <label style={{ color: "#FFFFFF" }}>
                              Số điện thoại:
                            </label>
                          }
                        >
                          <Input className={styles.customInput} inputMode="numeric" />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          name="province_id"
                          label={
                            <label style={{ color: "#FFFFFF" }}>Khu vực:</label>
                          }
                        >
                          <Select
                            showSearch
                            filterOption={filterOption}
                            placeholder="Chọn khu vực"
                            onChange={(e) => onSelectProvince(e)}
                          >
                            {PROVINCE.map((service) => {
                              return (
                                <option key={service.id} value={service.id}>
                                  {service.name}
                                </option>
                              );
                            })}
                          </Select>
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          name="clinic_id"
                          label={
                            <label style={{ color: "#FFFFFF" }}>
                              Phòng khám:
                            </label>
                          }
                        >
                          <Select
                            showSearch
                            filterOption={filterOption}
                            placeholder="Chọn phòng khám"
                          >
                            {dataClinic &&
                              dataClinic.map((clinic) => {
                                return (
                                  <option key={clinic.id} value={clinic.id}>
                                    {clinic.name}
                                  </option>
                                );
                              })}
                          </Select>
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          name="note"
                          label={
                            <label style={{ color: "#FFFFFF" }}>
                              Vấn đề cần tư vấn:
                            </label>
                          }
                          style={{ marginBottom: isMobile ? 18 : 14 }}
                        >
                          <TextArea className={styles.customTextArea} />
                        </FormItem>
                      </Col>
                    </Row>
                    <div style={{ lineHeight: "15px" }}>
                      <span className={styles.note}>LƯU Ý: Trường có dấu</span>
                      <span className={styles.labelRequired}>*</span>
                      <span className={styles.note}>là trường bắt buộc</span>
                    </div>
                    <Button
                      className={styles.btnContact}
                      type="primary"
                      htmlType="submit"
                      onClick={onSubmit}
                    >
                      {isMobile ? "Liên hệ ngay" : "Đặt lịch tư vấn"}
                    </Button>
                  </Form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
