import wpAxios from "@/src/util/request";
import { BASE_URL } from "@/src/const/const";
import axios from "axios";

export async function getDetailClinicAxios(slug) {
  try {
    const result = await wpAxios().get(
      `${BASE_URL}/clinic/findBySlugWebsite/${slug}`,
      {
        params: {},
      }
    );
    return result.data;
  } catch (error) {
    console.log("Error: " + error);
  }
}
export async function getSomeClinicProperties(slug) {
  try {
    const result = await wpAxios().get(
      `${BASE_URL}/clinic/getProperties/${slug}`,
      {
        params: {},
      }
    );
    return result.data;

  } catch (error) {
    console.log("Error: " + error);
  }
}

export async function getDetailClinic(slug) {
  try {
    const result = await fetch(`${BASE_URL}/clinic/findBySlug/${slug}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      cache: "force-cache",
    });
    const res = await result.json();
    // console.log("res ====", res);
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function parseSlugDetail(slug) {
  try {
    const result = await wpAxios().get(
      `${BASE_URL}/parse-slug/detail-clinic?slug=${slug}`
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getAllClinicSlug() {
  const result = await wpAxios().get(`${BASE_URL}/clinic/getAllClinicSlug`);
  // const result = await fetch(`${BASE_URL}/clinic/getAllClinicSlug`, {
  //   method: "GET", // *GET, POST, PUT, DELETE, etc.
  //   cache: "force-cache",
  // });
  // const res = await result.json();
  return result?.data;
}

export async function getListPosts(params) {
  try {
    const result = await wpAxios().get(
      `${BASE_URL}/admin/post/public`,{
        params:{
          page: params?.page || 1,
          limit: 10,
          user_id : params?.user_id,
          clinic_slug : params?.clinic_slug,
          doctor_slug : params?.doctor_slug,
          doctor_id : params?.doctor_id
        }
      }
    );
    return result?.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getDetailPost(id, params) {
  try {
    const result = await wpAxios().get(`${BASE_URL}/admin/post/${id}`, {
      params:{
        clinic_slug: params?.clinic_slug,
        doctor_slug: params?.doctor_slug
      }
    });
    return result?.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getClinicBlog(id) {
  const result = await wpAxios().get(`${BASE_URL}/connect-clinic-blog/${id}`);

  return result?.data;
}

export async function getDataForClinicDetail(params){
  const result = await wpAxios().get(`${BASE_URL}/clinic/getDataForClinicDetail`, {
    params: {
      clinic_id: params?.clinic_id,
      clinic_slug: params?.clinic_slug,
      longitude: params?.longitude,
      latitude: params?.latitude
    }
  });
  return result?.data;
}
