import wpAxios from "@/src/util/request";
import { BASE_URL } from "@/src/const/const";

export async function getDetailClinic(id) {
  try {
    const { data } = await wpAxios().get(`${BASE_URL}/admin/clinic/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getDoctor(slug) {
  try {
    const { data } = await wpAxios().get(
      `${BASE_URL}/admin/doctor/findBySlug/${slug}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}
export async function getSomeDoctorProperties(slug) {
  try {
    const { data } = await wpAxios().get(
      `${BASE_URL}/admin/doctor/getProperties/${slug}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getDiaries(doctor_id) {
  const params = {
    page: 1,
    limit: 8,
    doctor_id: doctor_id,
  };
  try {
    const { data } = await wpAxios().get(`${BASE_URL}/diary-post/findFilter`, {
      params: params,
    });
    return data;
  } catch (error) {
    return false;
  }
}

export async function getAllDoctorSlug() {
  try {
    const res = await wpAxios().get(`${BASE_URL}/admin/doctor/getAllSlug`);
    return res.data;
  } catch (error) {}
}

export async function getDoctorByName(name) {
  const result = await wpAxios().get(
    `${BASE_URL}/admin/doctor/name?name=${name}`
  );
  return result.data;
}

export async function searchDoctor(
  name,
  service,
  province_id,
  district_id,
  page,
  limit
) {
  const params = {
    name: name,
    service: service,
    province_id: province_id,
    district_id: district_id,
    page: page ? page : 1,
    limit: limit ? limit : 10,
  };
  try {
    const res = await wpAxios().get(`${BASE_URL}/admin/doctor/search`, {
      params: params,
    });
    return res.data;
  } catch (error) {}
}

export async function searchDoctorV2(
  name,
  service,
  province_id,
  district_id,
  page,
  limit
) {
  const params = {
    name: name,
    service: service,
    province_id: province_id,
    district_id: district_id,
    page: page ? page : 1,
    limit: limit ? limit : 10,
  };
  try {
    const res = await wpAxios().get(`${BASE_URL}/admin/doctor/search-v2`, {
      params: params,
    });
    console.log("res.data", res.data);
    return res.data;
  } catch (error) {
    return false;
  }
}

export async function getAllPostFromDoctor(doctor_id, page = 1) {
  try {
    const result = await wpAxios().get(
      `${BASE_URL}/admin/post/public?doctor_id=${doctor_id}&limit=10&page=${page}`
    );

    return result?.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getDetailPostAxios(id) {
  try {
    const result = await wpAxios().get(`${BASE_URL}/admin/post/${id}`);
    return result?.data;
  } catch (error) {
    console.log(error);
  }
}
