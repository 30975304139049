"use client";
import { Input, Drawer, List, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState } from "react";
import { strVNForSearch } from "@/src/util/util";
import _ from "lodash";
import useWindowSize from "@/src/hooks/useWindowSize";
import { filterOption } from "../../util/util";

export default function FriendlySelect({
  options,
  className,
  placeholder,
  onChange = () => {}, // pass when have special onChange
  onClear = () => {},
}) {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const debounceSearch = _.debounce((searchValue) => {
    setFilteredOptions(
      options.filter((option) =>
        strVNForSearch(option.label).includes(strVNForSearch(searchValue))
      )
    );
  }, 300);
  const [value, setValue] = useState(null);
  const showFriendlySelect = useWindowSize().width <= 480;
  const [open, setOpen] = useState(false);

  return (
    <>
      <style>
        {`
        .ant-drawer-header-title{
          flex-direction: row-reverse;
          .ant-drawer-close{
            margin-right: 0;
          }
        }
        .ant-input-affix-wrapper{
        border-color: #d9d9d9 !important;
        }
        .ant-list-item{
          padding: 10px 0;
          &:hover{
            background-color: #f5f5f5;
          }
        }
        `}
      </style>
      <Select
        className={className}
        filterOption={filterOption}
        placeholder={placeholder}
        allowClear
        onClear={() => {
          setValue(null);
          onClear();
        }}
        showSearch={!showFriendlySelect}
        open={showFriendlySelect ? false : undefined}
        onClick={() => {
          if (showFriendlySelect) {
            setOpen(true);
          }
        }}
        value={value}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        onChange={onChange}
        options={options}
        onSelect={(value, option) => {
          setValue(value);
        }}
      />

      {showFriendlySelect && (
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
          destroyOnClose
          title="Tìm kiếm"
          placement="right"
          className="friendly-select"
        >
          <Input
            allowClear
            onChange={(e) => debounceSearch(e.target.value)}
            placeholder="Tìm kiếm"
            prefix={<SearchOutlined />}
          />
          <List
            dataSource={filteredOptions}
            renderItem={(item) => (
              <List.Item
                onClick={() => {
                  // onChange with value and option same as Select
                  onChange(item.value, item);
                  setValue(item.value);
                  setOpen(false);
                }}
              >
                {item.label}
              </List.Item>
            )}
          />
        </Drawer>
      )}
    </>
  );
}
