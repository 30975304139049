import { useCallback, useState } from "react";
import { Avatar, Button, Form, Input, message } from "antd";
import { createContactAdvice } from "@/src/app/service";
import { usePathname } from "next/navigation";
import { UserOutlined } from "@ant-design/icons";
import Image from "next/image";
import iconUpdown from "../../../public/icon/icon-up-down.svg";
import iconArrow from "@/public/icon/arrow-float-button.svg";
import chatAlert from "@/public/icon/chat-alert.svg";
import ImageCommon from "../image/image";
import logoNhakhoaHub from "../../../public/image/logo-nhakhoahub-chat.png";
import "./chat-bot-mobile.scss";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { searchClinicByLocation } from "@/src/app/tim-nha-khoa/service";
import { IMAGE_TYPE } from "@/src/const/const";
import iconLocation from "@/public/icon/icon-location-newest.svg";
import iconStar from "@/public/icon/icon-start-3d.svg";
import iconCheckMark from "@/public/icon/icon-check-mark.png";
import { getDetailClinicAxios } from "@/src/app/phong-kham/[slug]/service";
const { TextArea } = Input;
const FormItem = Form.Item;

const FormChatbotMobile = ({ clinic }) => {
  const pathname = usePathname();
  const [form] = Form.useForm();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clinicInArea, setClinicInArea] = useState(null);
  const [btnDisabledIds, setBtnDisabledIds] = useState([]);

  const getClinicInArea = async (latitude, longitude, clinicId) => {
    try {
      const dataClinic = await searchClinicByLocation(
        1,
        longitude,
        latitude,
        null,
        null,
        null,
        null,
        3,
        clinicId,
        "CLEAR_PIN"
      );
      setClinicInArea(dataClinic?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onCreateContact = async (clinicId) => {
    const fiedsValue = await form.validateFields();
    const body = {
      ...fiedsValue,
      type: "ADMIN",
      created_at: moment(new Date()).format("HH:mm - DD/MM/YYYY"),
      landing_page_url: localStorage?.getItem("firstVisitPage")
        ? localStorage?.getItem("firstVisitPage")
        : window?.location?.href,
      referrer: localStorage?.getItem("referrer")
        ? localStorage?.getItem("referrer")
        : "",
      url: window?.location?.href,
      clinic_id: clinicId,
    };
    await createContactAdvice(body);
  };

  const onFinish = async () => {
    try {
      await form.validateFields();
      setIsLoading(true);

      let clinicDetail = null;

      // Kiểm tra và lấy thông tin clinicDetail nếu chưa có clinic
      if (!clinic) {
        const pathParts = pathname.split("/").filter(Boolean);
        const isValidPath =
          pathname.startsWith("/phong-kham/") && pathParts.length === 2;
        const slug = isValidPath ? pathParts[1] : null;

        if (slug) {
          clinicDetail = await getDetailClinicAxios(slug);
        }
      }

      // Gọi API tạo contact
      const clinicId = clinic?.id || clinicDetail?.id;
      await onCreateContact(clinicId);

      // Lấy thông tin tọa độ để tìm các phòng khám trong khu vực
      const latitude =
        clinic?.latitude ||
        clinicDetail?.address?.[0]?.latitude ||
        clinic?.address?.[0]?.latitude;

      const longitude =
        clinic?.longitude ||
        clinicDetail?.address?.[0]?.longitude ||
        clinic?.address?.[0]?.longitude;

      if (latitude && longitude) {
        getClinicInArea(latitude, longitude, clinicId);
      }
      setIsSuccess(true);
      setIsLoading(false);
      autoScroll();
    } catch (error) {
      message.error("Vui lòng kiểm tra lại thông tin đăng bài!");
    }
  };
  const autoScroll = () => {
    const container = document.querySelector(".chatBody");
    const target = document.querySelector(".wp-content-success");
    let maxAttempts = 10;
    let attempts = 0;
    const targetRect = target?.getBoundingClientRect();
    if (
      !isNaN(container?.offsetTop) &&
      !isNaN(container?.scrollTop) &&
      targetRect?.height > 0 &&
      targetRect?.width > 0
    ) {
      const targetPosition = target.offsetTop - container.offsetTop;
      requestAnimationFrame(() => {
        container.scrollTo({
          top: targetPosition,
          behavior: "smooth",
        });
      });
    } else if (attempts < maxAttempts) {
      attempts++;
      setTimeout(autoScroll, 100);
    } else {
      console.log("Target không tìm thấy sau " + maxAttempts + " lần thử.");
    }
  };

  return (
    <div className="wpFormChatboxMobile">
      <div className="chatHeader">
        <span>Liên hệ tư vấn</span>
      </div>
      <div className="chatBody">
        <div className={"chatContainer"}>
          <div className={"rowChat"}>
            <div className={"chatContent"} style={{ width: "fit-content" }}>
              <span>
                Bạn đang quan tâm đến vấn đề gì? Hãy để lại thông tin, chúng tôi
                sẽ hỗ trợ tư vấn:
              </span>
            </div>
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              className={"chatContent"}
            >
              <FormItem
                name="customer_name"
                label="Họ và tên của bạn:"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập tên của bạn",
                  },
                ]}
              >
                <Input className="chatBoxInput" style={{ width: "100%" }} />
              </FormItem>
              <FormItem
                name="customer_phone_number"
                label="Số điện thoại:"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập số điện thoại của bạn",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const phoneNumberRegex =
                        /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
                      if (value && !phoneNumberRegex.test(value)) {
                        return Promise.reject("Số điện thoại không hợp lệ!");
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  className="chatBoxInput"
                  style={{ width: "100%" }}
                  inputMode="numeric"
                />
              </FormItem>
              <FormItem
                name="note"
                label="Vấn đề cần tư vấn:"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập vấn đề của bạn",
                  },
                ]}
              >
                <TextArea
                  style={{ width: "100%" }}
                  rows={4}
                  autoSize={{ minRows: 3 }}
                />
              </FormItem>
            </Form>
          </div>
        </div>

        {isSuccess && (
          <>
            <div className="chatContainer" style={{ marginTop: "20px" }}>
              <div className={"chatContent wp-content-success"}>
                <b>Thật tuyệt vời!</b>
                <div>
                  <span>
                    Vui lòng lưu ý điện thoại của bạn để được tư vấn tận tình
                    nhé, chúc anh chị một ngày tốt lành
                  </span>
                </div>
              </div>
            </div>
            {clinicInArea && (
              <>
                <div className={"chatContent"} style={{ marginTop: "10px" }}>
                  <div>
                    Bạn có thể lựa chọn thêm nha khoa cùng khu vực để nhận tư
                    vấn miễn phí:
                  </div>
                </div>
                {clinicInArea.map((item, index) => {
                  const logo = item?.image?.find(
                    (item) => item.image_type === IMAGE_TYPE.avatar
                  );
                  return (
                    <div key={index} className={"relatedClinic"}>
                      <div className={"clinicItem"}>
                        <div className={"clinicContainer"}>
                          <div className={"wpLeft"}>
                            <ImageCommon data={logo} style={"logo"} />

                            {item?.distance > 0 && (
                              <div className={"distance"}>
                                {(item.distance / 1000)?.toFixed(1)}km
                              </div>
                            )}
                          </div>
                          <div className={"wpRight"}>
                            <div className={"titleClinic"}>{item?.name}</div>
                            {item?.address?.length > 0 && (
                              <div className={"firstLineTag"}>
                                <div className={"wpTagInfo"}>
                                  <Image
                                    src={iconLocation}
                                    className={"iconLocation"}
                                    alt="Location icon"
                                  />
                                  {item.address[0]}
                                </div>
                              </div>
                            )}
                            <div className={"wpRatingLine"}>
                              {item?.rating_overview?.overall_average ? (
                                <>
                                  <div className={"wpRatingInLine"}>
                                    <div className={"ratingPoint"}>
                                      <div className={"point"}>
                                        {item?.rating_overview?.overall_average.toFixed(
                                          1
                                        )}
                                      </div>
                                      <Image
                                        className="iconRatingStar"
                                        src={iconStar}
                                        alt="icon"
                                        width={12}
                                        height={12}
                                      />
                                    </div>
                                  </div>
                                  <div className={"dash"}></div>
                                </>
                              ) : null}
                              <div className={"ratingQuantity"}>
                                {item?.rating_overview?.number_of_ratings} Đánh
                                giá
                              </div>
                              <div className={"dash"}></div>
                              <div className={"numberOfConsultation"}>
                                {item?.total_contact ? item?.total_contact : 0}{" "}
                                Người đặt lịch
                              </div>
                            </div>
                          </div>
                        </div>
                        {btnDisabledIds.includes(item.id) ? (
                          <Image
                            src={iconCheckMark}
                            alt="iconCheckMark"
                            width={30}
                            height={30}
                            loading="eager"
                            style={{
                              margin: "8px auto -4px",
                            }}
                          />
                        ) : (
                          <Button
                            className="btn-receive-advice"
                            onClick={() => {
                              onCreateContact(item.id);
                              setBtnDisabledIds((prev) => [...prev, item.id]);
                            }}
                            aria-label="confirm"
                            // disabled={btnDisabledIds.includes(item.id)}
                          >
                            Đồng ý
                          </Button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </>
        )}
      </div>
      <div className="chatFooter">
        <Button
          className={`btnChatBox ${isSuccess ? "disabledBtn" : ""}`}
          onClick={onFinish}
          disabled={isSuccess}
          loading={isLoading}
        >
          Gửi yêu cầu
        </Button>
      </div>
    </div>
  );
};

export default FormChatbotMobile;
