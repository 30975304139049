"use client";

import React, { useState, useMemo, useEffect } from "react";

import iconCall from "../../../public/icon/icon-call-phone.svg";
import iconMess from "../../../public/icon/icon-messenger.svg";
import iconZalo from "../../../public/icon/icon-zalo.svg";
import "./call-to-action.scss";
import Image from "next/image";
import PhoneNumber from "../dialog/phone-number";
import Chatbot from "../dialog/chatbot";
import { usePathname } from "next/navigation";
import { useSession } from "next-auth/react";
import { Modal } from "antd";
import { createCallLogs } from "@/src/app/phong-kham/[slug]/service-server";
import _ from "lodash";

const IconCTA = () => {
  const path = usePathname();
  if (path == "/embed-get-coupon") return;
  const { data: session } = useSession();
  const [openPhoneNumber, setOpenPhoneNumber] = useState(false);
  const [isFabOpen, setIsFabOpen] = useState(false);

  const handleShowModal = () => {
    const sizeDesktop =
      typeof window !== "undefined" && window.innerWidth > 1200 ? true : false;
    if (!sizeDesktop) {
      window.open("tel:0976654560", "_self");
    } else {
      setOpenPhoneNumber(true);
    }
  };

  const handleOk = () => {
    setOpenPhoneNumber(false);
  };

  const handleCancel = (type) => {
    setOpenPhoneNumber(false);
  }; //???

  const toggleFab = () => setIsFabOpen(!isFabOpen);

  const navigateMessenger = () => {
    window.open(`https://m.me/nhakhoahub.vn`, "_blank", "noopener noreferrer");
  };

  const isPathValid = useMemo(() => {
    const regex = /^\/phong-kham\/.+/;
    const result = regex.test(path);
    return result;
  }, [path]);

  const [showNotification, setShowNotification] = useState(false);
  const [countdown, setCountdown] = useState(4);

  useEffect(() => {
    if (path === "/") {
      setShowNotification(true);
      localStorage.setItem("firstVisitHomePage", "true");
    } else if (path !== "/") {
      localStorage.setItem("firstVisitHomePage", "false");
    }
    return () => {
      localStorage.removeItem("firstVisitHomePage");
    };
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      setShowNotification(false);
    }
    const timer = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [countdown]);

  const saveDataCall = async () => {
    const userAgent = navigator?.userAgent;
    const url = window?.location?.href;
    const params = {
      clinic_id: 63,
      to_phone_number: "0976654560",
      landing_page_url: localStorage?.getItem("firstVisitPage")
        ? localStorage?.getItem("firstVisitPage")
        : window?.location?.href,
      referrer: localStorage?.getItem("referrer")
        ? localStorage?.getItem("referrer")
        : "",
      user_agent: userAgent,
      url: url,
    };

    await createCallLogs(params);
  };
  const throttledHandleCallPhone = _.throttle(saveDataCall, 10000, {
    leading: true,
    trailing: false,
  });
  const handleCallPhone = async () => {
    window.open(`tel:0976654560`, "_self");
  };

  return (
    <div className="wp-cta-action">
      {!isPathValid && (
        <div className="wp-cta">
          <div className="animation">
            <div className="coccoc-alo-ph-circle"></div>
            <div className="coccoc-alo-ph-circle-fill"></div>
            <div onClick={handleShowModal} className="phone">
              <Image src={iconCall} alt="iconCall" width={66} height={66} />
            </div>
          </div>
          <div onClick={navigateMessenger} className="cta-link">
            <Image src={iconMess} alt="iconMess" width={62} height={62} />
          </div>
          <a
            href="https://zalo.me/0976654560"
            className="cta-link"
            style={{ padding: "9px" }}
          >
            <Image src={iconZalo} width={48} height={48} alt="iconZalo" />
          </a>
        </div>
      )}
      {!isPathValid && (
        <div
          className={`wp-fab ${isFabOpen ? "prioritize-wp-fab" : ""}`}
          onChange={toggleFab}
          onBlur={toggleFab}
        >
          <input
            id="fabCheckbox"
            type="checkbox"
            className="fab-checkbox"
            checked={isFabOpen}
          />
          <label htmlFor="fabCheckbox" className="fab">
            <em
              className={`icon-cps-fab-menu ${
                !isFabOpen ? "animation-btn" : ""
              }`}
            ></em>
          </label>
          <div className="coccoc-alo-ph-circle"></div>
          <div className="coccoc-alo-ph-circle-fill"></div>
          <div className={`fab-wheel ${isFabOpen ? "open" : ""}`}>
            <div onClick={handleCallPhone}>
              <div
                rel="nofollow"
                id="button-callnow-tracking"
                onClick={throttledHandleCallPhone}
                className="fab-action fab-action-2"
              >
                <span className="fab-title">Gọi miễn phí</span>
                <div className="fab-button fab-button-2">
                  <em className="icon-cps-phone"></em>
                </div>
              </div>
            </div>
            <div
              rel="nofollow"
              onClick={navigateMessenger}
              className="fab-action fab-action-3"
            >
              <span className="fab-title">Chat trên Messenger</span>
              <div className="fab-button fab-button-3">
                <Image src={iconMess} alt="iconMess" width={30} height={30} />
              </div>
            </div>
            <a
              href="https://zalo.me/0976654560"
              target="_blank"
              rel="noopener nofollow"
              className="fab-action fab-action-4"
            >
              <span className="fab-title">Chat trên Zalo</span>
              <div className="fab-button fab-button-4">
                <em className="icon-cps-chat-zalo"></em>
              </div>
            </a>
          </div>
          <div
            className="suggestions-chat-box hidden"
            style={{ display: "none" }}
          >
            <div className="box-content d-flex justify-content-around align-items-center">
              <i
                id="btnClose"
                aria-hidden="true"
                className="fa fa-times-circle"
              ></i>
              <p className="mb-0 font-14">
                Liên hệ ngay{" "}
                <em aria-hidden="true" className="fa fa-hand-o-right"></em>
              </p>
            </div>
          </div>
          <div className="floating-overlay"></div>
        </div>
      )}
      <Chatbot avatarUser={session?.user?.image[0]} />
      <PhoneNumber
        openPhoneNumber={openPhoneNumber}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <Modal
        open={showNotification}
        width={574}
        onOk={() => setShowNotification(false)}
        closeIcon={null}
        onCancel={() => setShowNotification(false)}
        footer={
          <div>
            <p>Phần này sẽ tự đóng sau {countdown} giây.</p>
          </div>
        }
        className="wp-notification"
      >
        <p className="wp-content">
          Website đang hoạt động thử nghiệm và đang trong quá trình đăng ký bộ
          công thương.
        </p>
      </Modal>
    </div>
  );
};

export default IconCTA;
